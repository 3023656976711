import {useEffect, useState} from "react"
import useMediaQuery from '@mui/material/useMediaQuery';

import { MainTable } from "./components/MainTable"
import { AppStyled } from "./styled";
import { Loader } from "./components/Loader"
import { ApiService } from "./services/ApiService"
import {Header} from "./components/Header";
import {Intro} from "./components/Intro";
import {About} from "./components/About";
import {Footer} from "./components/Footer";
import {Mobile} from "./components/Mobile";
import {CoreService} from "./services/CoreService";


export const cleanUrl = (shopUrl) => {
    let url = shopUrl
    if (url.startsWith('http://')) {
        url = url.split('http://')[1]
    }
    if (url.startsWith('https://')) {
        url = url.split('https://')[1]
    }
    if (url.startsWith('www.')) {
        url = url.split('www.')[1]
    }
    return 'https://' + url.split('/')[0]
}


function App() {

    useEffect(() => {
        CoreService.init()
    }, [])

    const [shopUrl, setShopUrl] = useState('')
    const [state, setState] = useState(null)
    const [errorMsg, setErrorMsg] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const isMobile = useMediaQuery('(max-width:990px)');

    const search = async () => {
        if (!shopUrl) {
            return
        }
        const cleanShopUrl = cleanUrl(shopUrl)
        setIsLoading(true)
        const data = await ApiService.fetchData(cleanShopUrl)
        setIsLoading(false)
        if (data?.data) {
            if (!data.data.success) {
                setState(null)
                setErrorMsg('Not a Shopify website')
                return
            }
            setState(data.data)
            setTimeout(() => {
                window.scrollTo({ top: document.querySelector('.table-container').scrollHeight - 300, behavior: 'smooth' })
            }, 200)
        }
    }

    const onInputChange = (e) => {
        setErrorMsg('')
        setShopUrl(e.target.value)
    }

    const onEnterClicked = (e) => {
        if (e.keyCode === 13) {
            search()
        }
    }

  return (
      isMobile
          ? <AppStyled className="App"><Mobile /></AppStyled>
          :
      <AppStyled className="App">
          <Loader isOn={isLoading} />
          <Header />
          <Intro shopUrl={shopUrl} errorMsg={errorMsg} onInputChange={onInputChange} onEnterClicked={onEnterClicked} search={search} />
          {state ? <MainTable data={state} domain={shopUrl}/> : null}
          <About />
          <Footer />
      </AppStyled>
  );
}

export default App;
