import { Player } from '@lottiefiles/react-lottie-player'

export const About = () => {
    return (
        <div className="about">
            <div className="side a">
                <div className="title">Collect data within one click from any public Shopify website!</div>
                <ul>
                    <li>View all the products and collections data</li>
                    <li>Export the data to CSV file</li>
                    <li>Free until 500 products</li>
                    <li>Unlimited number of websites</li>
                </ul>
            </div>
            <div className="side b">
                <Player
                    className="lottie-player"
                    src={"https://assets10.lottiefiles.com/packages/lf20_8ologodb.json"}
                    autoplay={true}
                    loop={true}
                />
            </div>
        </div>
    )
}