import axios from 'axios'

const functionUrl = 'https://molly-mg49v.ondigitalocean.app/api' // 'https://us-central1-molly-95c63.cloudfunctions.net/molly_1'
//
// sites to test - https://maidenhome.com/

export const ApiService = {
    fetchData(shopUrl) {
        try {
            // https://partakefoods.com/
            return axios.get(`${functionUrl}?targetUrl=${shopUrl}&source=client`)
        } catch (e) {
            console.log(e);
        }
    }
}
