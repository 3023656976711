import { Player } from "@lottiefiles/react-lottie-player"

export const Mobile = () => {
    return (
        <div className="mobile">
            <div className="title">Please visit me on Desktop :)</div>
            <Player
                className="lottie-player"
                src={"https://assets10.lottiefiles.com/packages/lf20_8ologodb.json"}
                autoplay={true}
                loop={true}
            />
        </div>
    )
}