import { Backdrop } from "@mui/material"
import { Player } from "@lottiefiles/react-lottie-player";
import SearchLotti from "../assets/77218-search-imm.json";

export const Loader = ({ isOn }) => {
    return (
        <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isOn}>
            <Player
                className="player-wrapper"
                autoplay
                loop
                background={'none'}
                src={SearchLotti}
            >
            </Player>
        </Backdrop>
    )
}
