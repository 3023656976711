import styled from "@emotion/styled";
// import img from '../assets/rachit-tank-2cFZ_FB08UM-unsplash.jpg';
// import img from '../assets/malvestida-u79wy47kvVs-unsplash.jpg';
import img from '../assets/daniel-storek-JM-qKEd1GMI-unsplash.jpg';

const primaryColor = '#8e44ad';
const primaryLightColor = '#ac5cce';

export const AppStyled = styled.div`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }

  .primary {
    color: ${primaryColor};
  }

  .MuiButton-root {
    background-color: ${primaryColor};
    padding: 10px;
    width: 180px;

    &:hover {
      background-color: ${primaryLightColor};
    }
  }

  /* Intro */

  .intro-section {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${img});
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 70vh;
    display: flex;
    justify-content: center;

    .content {
      display: flex;
      width: 80%;
      flex-direction: column;
      justify-content: center;

      .title-wrapper {
        margin-bottom: 20px;

        .brand {
          font-weight: 600;
          font-size: 40px;
          color: ${primaryColor};
          margin-bottom: -10px;
        }

        .title {
          font-weight: 300;
          font-size: 30px;
          color: #fff;
        }
      }

      .text-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        .MuiFormControl-root {
          width: 100%;

          .MuiInputBase-root {
            margin-right: 10px;
            box-shadow: 0px 0px 7px -4px rgba(0, 0, 0, 0.75);
            height: 50px;
            background-color: #fff;

            input {
              padding: 0 20px;
            }
          }
        }
      }

      .error-msg {
        min-height: 30px;
        color: #a30000;
        margin-top: 10px;
      }
    }
  }

  .about {
    margin: 100px 0;
    display: flex;

    .side {
      display: flex;
      justify-content: center;
      width: 50%;

      &.a {
        padding-left: 10%;
        flex-direction: column;

        .title {
          font-size: 30px;
          font-weight: 300;
        }

        ul {
          margin: 20px 0 0 15px;

          li {
            margin: 10px 0;
            font-size: 18px;
          }
        }
      }

      &.b {
        .lottie-player {
          width: 50%;
        }
      }
    }
  }


  .intro {
    display: flex;
    height: 100vh;
    align-items: center;
    padding: 40px;

    .intro-text {
      margin-top: -100px;
      width: 40%;

      .title {
        font-size: 36px;
        font-family: 'Barlow', sans-serif;
      }

      .subtitle {
        margin-top: 20px;
        font-weight: 300;
        font-size: 22px;
      }
    }

    .intro-img {
      width: 70%;

      .player-wrapper {
        width: 100%;
      }
    }
  }

  /* Search */

  .search {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;

    .title {
      font-size: 28px;
      font-family: 'Barlow', sans-serif;
    }

    .search-input {
      width: 80%;
      margin: 20px auto;

      .MuiInputBase-root {
        box-shadow: 0px 0px 7px -4px rgba(0, 0, 0, 0.75);
        height: 50px;
        border-radius: 24px;

        input {
          text-align: center;
        }
      }
    }


  }

  /* Table */

  .table-container {
    width: 90%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 24px;
    margin: -72px auto 0 auto;
    border-top: 1px solid #ccc;

    .table-bar {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .part {
        span {
          display: inline-block;
          margin-right: 20px;
        }

        button {
          font-size: 12px;
          height: 30px;
          width: 80px;
        }
      }
    }
  }

  .table-row {
    height: 110px !important;
    max-height: 110px !important;
  }

  .table-cell {
    text-align: center !important;
    max-height: 110px !important;
    height: 110px !important;
    overflow: scroll;
  }

  .link {
    color: ${primaryColor};
    text-decoration: none;
    transition: .2s;

    &:hover {
      color: ${primaryLightColor};
    }
  }

  .product-img {
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .footer {
    background-color: ${primaryColor};
    color: #fff;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 22px;
      font-weight: 100;
      margin-bottom: 10px;
    }
    .talk-to-me {
      font-size: 18px;
      a {
        color: #fff;
        //text-decoration: none;
      }
    }
  }
  
  .mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    .title  {
      text-align: center;
      font-size: 20px;
      margin: 50px 0;
    }
    //.lottie-player {
    //}
  }

`
