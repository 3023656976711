import { Button, TextField } from "@mui/material";

export const Intro = ({ shopUrl, errorMsg, onInputChange, onEnterClicked, search }) => {
    return (
        <div className="intro-section">
            <div className="content">
                <div className="title-wrapper">
                    <div className="brand">MOLLY.</div>
                    <div className="title">Extract Data from any Shopify website!</div>
                </div>
                <div className="text-wrapper">
                    <TextField placeholder="Enter Shopify Website" className="search-input" value={shopUrl} variant="outlined" onKeyUp={onEnterClicked} onChange={onInputChange} />
                    <Button variant="contained" onClick={search}>
                        SEARCH
                    </Button>
                </div>
                <div className="error-msg">{errorMsg ? errorMsg : null}</div>
            </div>

        </div>
    )
}
