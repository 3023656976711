import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const CoreService = {
    init() {
        const firebaseConfig = {
            apiKey: "AIzaSyDmwQGfdFRcxT0bemFsgpdhQKdD93ud0bk",
            authDomain: "molly-95c63.firebaseapp.com",
            projectId: "molly-95c63",
            storageBucket: "molly-95c63.appspot.com",
            messagingSenderId: "919810187312",
            appId: "1:919810187312:web:2df23541cd94918cca8df5",
            measurementId: "G-PXMQTT4BMT"
        }
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
    }
}